<template>
  <PageContentLayoutOnly :aria-label="$t('workOrders.title')" role="region">
    <ModernTable
      :region="region"
      :filters="filters"
      :rows="rows"
      :columns="columns"
      :pagination="{
        perPage: 12
      }"
      :loading="loading"
      headerColor="#FFF"
      condensed
    >
      <template v-slot:header>
        <div :style="{ width: '100%', height: '60px' }">
          <b-button
            type="is-ghost"
            @click="filters.show = !filters.show"
            :style="{ float: 'right', background: 'none', border: 0 }"
            :class="'filter-button ' + (filters.show ? 'active' : '')"
          >
            <b-icon pack="mdi" icon="filter-outline" size="is-small" style="display: inline-block">
            </b-icon>
            <div style="display: inline-block">Filter</div>
          </b-button>
        </div>
      </template>
    </ModernTable>
  </PageContentLayoutOnly>
</template>

<script>
import _get from 'lodash/get'
import { mapGetters, mapState } from 'vuex'
import PageContentLayoutOnly from '@/containers/PageContentLayoutOnly'
import ModernTable from '@/components/tables/Modern/Table'
import SimpleButton from '@/components/buttons/Simple'
import { parseDate } from '@/utilities/Date/parse'
import { workOrderList } from '@/services/WorkOrders/store'

export default {
  name: 'WorkOrders',
  components: { PageContentLayoutOnly, ModernTable },

  data: () => ({
    isDebug: true,
    loading: false,
    selectedRow: null,
    unitID: 0,
    workOrders: [],

    styles: {
      dateSelector: {
        display: 'flex',
        padding: '12px',
        textAlign: 'center',
        verticalAlign: 'middle',
        flexWrap: 'wrap',
        alignItems: 'center',
        justifyContent: 'flex-end'
      },
      filterButton: {
        float: 'right',
        marginTop: '4px'
      }
    },

    region: {},
    filters: {
      show: false
    },
    rows: [],
    columns: [
      {
        field: 'id',
        numeric: true,
        label: 'ID',
        aria: 'Work Order ID',
        width: '3%',
        sortable: true,
        searchable: true
      },
      {
        field: 'type',
        label: 'Type',
        aria: 'Type',
        width: '10%',
        sortable: true,
        searchable: true
      },
      {
        field: 'vendorName',
        label: 'Vendor',
        aria: 'Vendor',
        width: '15%',
        sortable: true,
        searchable: true
      },
      {
        field: 'description',
        label: 'Description',
        aria: 'Description',
        width: '15%',
        sortable: true,
        searchable: true
      },
      {
        field: 'address',
        label: 'Address',
        aria: 'Address',
        width: '15%',
        sortable: true,
        searchable: true
      },
      {
        field: 'dateCreated',
        date: true,

        label: 'Date Created',
        aria: 'Date Created',
        width: '9%',
        sortable: true,
        searchable: true
      },
      {
        field: 'dateCancelled',
        date: true,

        label: 'Date Cancelled',
        aria: 'Date Cancelled',
        width: '9%',
        sortable: true,
        searchable: true
      },
      {
        field: 'dateCompleted',
        date: true,

        label: 'Date Completed',
        aria: 'Date Completed',
        width: '9%',
        sortable: true,
        searchable: true
      },
      {
        field: 'Details',
        label: 'Actions',
        width: '5%'
      }
    ]
  }),

  methods: {
    getWorkOrders: async function() {
      const { list: workOrders } = await workOrderList.dispatch('getWorkOrderList', {
        hoaId: this.currentHoaId,
        unitID: this.unit.unitID
      })

      if (workOrders) this.workOrders = workOrders
    },
    determineRows: function() {
      let list = this.workOrders
      if (!Array.isArray(list)) {
        return
      }

      const filteredList = list.filter(f => f.unitID == this.unitID)

      if (this.isDebug == true) console.debug('filteredList=' + JSON.stringify(filteredList))

      const dateFormat = 'LL'
      var rows = filteredList.map(entry => {
        const dateCreated = parseDate(_get(entry, 'createdDate', null), dateFormat)
        const dateCompleted = parseDate(_get(entry, 'completionDate', null), dateFormat)
        const dateCancelled = parseDate(_get(entry, 'cancelledDate', null), dateFormat)
        const id = _get(entry, 'workOrderID', null)
        const description = _get(entry, 'description', null)
        const type = _get(entry, 'workOrderTypeName')
        const vendor = _get(entry, 'vendorName', null)

        return {
          id,
          type: type,
          vendorName: vendor,
          description: description,
          address: _get(entry, 'locationDescription', ''),
          dateCreated: {
            date: dateCreated,
            format: dateFormat
          },
          dateCancelled: {
            date: dateCancelled,
            format: dateFormat
          },
          dateCompleted: {
            date: dateCompleted,
            format: dateFormat
          },
          details: {
            component: SimpleButton,
            props: {
              to: {
                path: `/workOrderTabs/${id}/workorderDetails#tab`
              },
              text: 'Details'
            }
          }
        }
      })

      this.rows = rows
    }
  },

  computed: {
    ...mapState({
      unit: state => state.hoa.unit
    }),
    ...mapGetters('user', ['currentHoaId'])
  },

  watch: {
    unit: 'getWorkOrders'
  },

  mounted() {
    if (this.unit && this.unit != undefined) {
      this.unitID = this.unit.unitID
    }

    this.loading = true
    this.getWorkOrders()
      .then(() => {
        if (this.workOrders) this.determineRows()
        this.loading = false
      })
      .catch(() => {
        this.loading = false
      })
  },

  beforeDestroy() {
    this.unsubscribe()
  },

  i18n: {
    messages: {
      en: { workOrders: { title: 'Work Orders' } }
    }
  }
}
</script>

<style></style>
